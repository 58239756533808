<template>
  <section class="elv-valuation-automation-page">
    <div class="elv-valuation-automation-header-info">
      <div class="elv-valuation-automation-operating">
        <div class="elv-valuation-automation-rule-button">
          <el-button
            :loading="executeLoading"
            :disabled="entityStore.entityDetail?.progress.derivativeAdjustmentJournalStatus === 'DOING'"
            class="elv-valuation-automation-rule-button__execute"
            :class="{
              'is-disabled': entityStore.entityDetail?.progress.derivativeAdjustmentJournalStatus === 'DOING'
            }"
            @click="openExecuteRulesConfirm"
          >
            <template v-if="entityStore.entityDetail?.progress.derivativeAdjustmentJournalStatus !== 'DOING'">
              <SvgIcon
                name="play-outline"
                width="16"
                height="16"
                fill="#838D95"
                class="elv-valuation-automation-rule-button__execute-icon"
              />
              <span>{{ t('report.manuallyExecuteRules') }}</span>
            </template>
            <span v-else-if="entityStore.entityDetail?.progress.derivativeAdjustmentJournalStatus === 'DOING'">
              <img src="@/assets/img/reports/sources-sync-loading.png" alt="loading" />{{
                t('report.rulesAreExecuting')
              }}</span
            >
          </el-button>

          <elv-button
            type="primary"
            round
            width="93"
            height="30"
            class="elv-financials-header-main-right__addRule"
            @click="onAddRule"
            ><SvgIcon name="source-add" width="14" height="14" class="icon" />{{ t('button.addNew') }}</elv-button
          >
        </div>
      </div>
    </div>
    <div class="elv-valuation-automation-container">
      <RuleTable
        ref="tableRef"
        activeTab="derivativeAdjustment"
        :ruleParams="ruleParams"
        :loading="loading"
        @onEditRule="onEditRule"
        @onChangePage="onChangePage"
        @getAutomationRuleList="getAutomationRuleList"
        @onChangePageSize="onChangePageSize"
        @onChangeStatus="onChangeStatus"
        @onOpenDeleteConfirm="onOpenDeleteConfirm"
      />
    </div>
  </section>

  <ElvMessageBox
    ref="ruleMessageBoxRef"
    :showCancelButton="messageBoxType === 'delete'"
    :cancelButtonText="t('button.cancel')"
    :confirmButtonText="messageBoxType === 'message' ? t('button.yes') : t('button.delete')"
    :loading="deleteLoading"
    :title="messageBoxType === 'message' ? t('report.rulesAreExecuting') : t('report.deleteRule')"
    @onConfirmEvent="onConfirmDeleteEvent"
    @onCancelEvent="onCancelEvent"
  >
    <template #content>
      <span class="elv-confirm-info-header__title">
        <template v-if="messageBoxType === 'message'">{{ t('message.pleaseWaitRules') }}</template>
        <template v-else>
          {{ t('message.deleteRuleMessage', { name: currentRuleData.name }) }}
        </template>
      </span>
    </template>
  </ElvMessageBox>

  <ElvMessageBox
    ref="ruleMessageBoxExecutingRef"
    :showCancelButton="true"
    :confirmButtonText="t('button.execute')"
    :cancelButtonText="t('button.cancel')"
    :title="currentRule?.name ? t('title.executeRuleName', { name: currentRule?.name }) : t('title.executeAllRules')"
    class="elv-automation-confirm-dialog is-longer"
    @onConfirmEvent="onConfirmExecutingEvent"
    @onCancelEvent="onCancelExecutingEvent"
  >
    <template #content>
      <div v-if="automationExecuteTips.valuationToJournal" class="elv-automation-confirm-info__list">
        <ol
          v-for="(item, index) in automationExecuteTips.valuationToJournal"
          :key="index"
          class="elv-automation-confirm-info__item"
        >
          <h4>{{ transformI18n(item.title) }}</h4>
          <li v-for="(olItem, i) in item.ol" :key="i">
            {{ transformI18n(olItem) }}
          </li>
          <template v-if="item?.ul">
            <div v-for="(ulItem, i) in item.ul" :key="i">-&nbsp;&nbsp;{{ transformI18n(ulItem) }}</div>
          </template>
        </ol>
      </div>
    </template>
  </ElvMessageBox>

  <RuleDialog
    ref="ruleDialogRef"
    activeTab="derivativeAdjustment"
    :title="ruleDialogMode === 'add' ? t('button.addRule') : t('button.editRule')"
    :currentRuleData="currentRuleData"
    :model="ruleDialogMode"
    :currencyList="currencyList"
    :derivateOptions="derivateOptions"
    :auxiliaryTypeList="auxiliaryTypeList"
    @resetList="onResetList"
  />
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import Sortable from 'sortablejs'
import utc from 'dayjs/plugin/utc'
import { useI18n } from 'vue-i18n'
import ConfigApi from '@/api/ConfigApi'
import LedgerApi from '@/api/LedgerApi'
import { ElMessage } from 'element-plus'
import { transformI18n } from '@/i18n/index'
import ValuationApi from '@/api/ValuationApi'
import { CurrencyItemType } from '#/ReportsTypes'
import { useEntityStore } from '@/stores/modules/entity'
import { automationExecuteTips } from '@/config/transactions'
import { useValuationStore } from '@/stores/modules/valuation'
import { useTransactionStore } from '@/stores/modules/transactions'
import RuleTable from '@/pages/Financials/Project/components/Automation/RuleTable.vue'
import RuleDialog from '@/pages/Financials/Project/components/Automation/RuleDialog/Index.vue'

dayjs.extend(utc)
const { t } = useI18n()
const route = useRoute()
const entityStore = useEntityStore()
const valuationStore = useValuationStore()
const transactionStore = useTransactionStore()

const loading = ref(false)
const deleteLoading = ref(false)
const tableRef = ref()
const ruleDialogRef = ref()
const currentRuleData = ref()
const ruleDialogMode = ref('add')
const ruleMessageBoxRef = ref()
const ruleMessageBoxExecutingRef = ref()
const auxiliaryTypeList: any = ref([])
const executeLoading = ref(false)
const messageBoxType = ref('message')
const ruleParams = ref({
  page: 1,
  limit: 20
})
const activeTab = ref('transfer')
const derivateOptions = ref<ElvSelectOptionType[]>([])
const currencyList = ref<CurrencyItemType[]>([])
const currentRule: any = ref({})

const entityId = computed(() => {
  return String(route.params?.entityId as string)
})

const currentEntityPermission = computed(() => {
  return entityStore.entityPermission()
})

/**
 * @description: 打开执行规则确认弹窗
 */
const openExecuteRulesConfirm = () => {
  if (
    (['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
      !currentEntityPermission.value?.automationRule?.execute &&
      activeTab.value === 'transfer') ||
    (activeTab.value !== 'transfer' && !currentEntityPermission.value?.businessData?.update)
  ) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  if (entityStore.entityDetail?.progress.transactionStatus !== 'DOING' || activeTab.value !== 'transfer') {
    currentRule.value = {}
    ruleMessageBoxExecutingRef.value?.onCheckMessageBoxDialog()
  } else {
    ElMessage.warning(t('message.sourceInExecuting'))
  }
}

/**
 * @description: 获取规则列表
 */
const getAutomationRuleList = async () => {
  try {
    loading.value = true
    const params: any = {
      ...ruleParams.value
    }
    await valuationStore.fetchDerivativeAdjustmentAutomationRuleList(entityId.value, params)
    tableRef.value?.tableElRef?.setScrollTop(0)
  } catch (error) {
    console.log(error)
  } finally {
    loading.value = false
  }
}

/**
 * @description: 修改分页大小
 * @param {number} limit
 */
const onChangePageSize = (limit: number) => {
  ruleParams.value.limit = limit
  ruleParams.value.page = 1
  getAutomationRuleList()
}

/**
 * @description: 修改页码
 * @param {number} page
 */
const onChangePage = (page: number) => {
  if (ruleParams.value.page !== page) {
    ruleParams.value.page = page
    getAutomationRuleList()
  }
}

/**
 * @description: 重置列表
 * @param {boolean} back 是否返回第一页
 */
const onResetList = (back: boolean) => {
  if (back) {
    ruleParams.value.page = 1
  }
  getAutomationRuleList()
}

/**
 * @description: 初始化拖拽排序
 */
const initSortable = () => {
  nextTick(() => {
    const table = tableRef.value?.tableElRef?.$el.querySelector('.el-table__body-wrapper tbody')
    let originalOrder: any
    Sortable.create(table, {
      animation: 300,
      handle: '.elv-transactions-automation-table-draggable__icon',
      onStart: () => {
        if (
          ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
          !currentEntityPermission.value?.automationRule?.update
        ) {
          ElMessage.warning(t('message.noPermission'))
          return
        }
        if (entityStore.entityDetail?.progress.transactionStatus === 'DOING') {
          messageBoxType.value = 'message'
          ruleMessageBoxRef.value?.onCheckMessageBoxDialog()
        }
        originalOrder = Array.from(table.children)
      },
      filter: '.el-table__row.el-table__row--level-0',
      onEnd: async ({ newIndex, oldIndex }) => {
        if (oldIndex !== newIndex) {
          try {
            let newList: any = []
            loading.value = true
            newList = valuationStore.derivativeAdjustmentAutomationRuleList.list.slice()
            newList.splice(Number(newIndex), 0, newList.splice(Number(oldIndex), 1)[0])
            const derivativeAdjustmentAutomationRuleIds = newList.map((item: any) => {
              return {
                derivativeAdjustmentAutomationRuleId: item.derivativeAdjustmentAutomationRuleId
              }
            })
            await ValuationApi.editDerivativeAdjustmentAutomationRulePriority(
              entityId.value,
              derivativeAdjustmentAutomationRuleIds
            )
            ElMessage.success(t('message.priorityChanged'))
            valuationStore.derivativeAdjustmentAutomationRuleList.list = []
            await getAutomationRuleList()
          } catch (error: any) {
            loading.value = false
            table.innerHTML = '' // 清空表格内容
            for (const row of originalOrder) {
              table.appendChild(row)
            }
            ElMessage.error(error.message)
          }
        }
      }
    })
  })
}

/**
 * @description: 打开删除确认弹窗
 * @param {object} row
 */
const onOpenDeleteConfirm = (row: any) => {
  if (
    ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
    !currentEntityPermission.value?.automationRule?.delete
  ) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  if (entityStore.entityDetail?.progress.transactionStatus === 'DOING') {
    messageBoxType.value = 'message'
    ruleMessageBoxRef.value?.onCheckMessageBoxDialog()
    return
  }
  currentRuleData.value = row
  messageBoxType.value = 'delete'
  ruleMessageBoxRef.value?.onCheckMessageBoxDialog()
}

/**
 * @description: 删除规则
 * @param {object} row
 */
const onDeleteRule = async (row: any) => {
  try {
    deleteLoading.value = true
    await ValuationApi.deleteDerivativeAdjustmentAutomationRule(
      entityId.value,
      row.derivativeAdjustmentAutomationRuleId
    )
    ElMessage.success(t('message.deleteSuccess'))
    ruleMessageBoxRef.value?.onCheckMessageBoxDialog()
    getAutomationRuleList()
  } catch (error: any) {
    console.log(error)
    ElMessage.error(error.message)
  } finally {
    deleteLoading.value = false
  }
}

/**
 * @description: 取消执行规则
 */
const onCancelExecutingEvent = () => {
  messageBoxType.value = 'message'
  ruleMessageBoxExecutingRef.value?.onCheckMessageBoxDialog()
}

/**
 * @description: 确认执行规则
 */
const onConfirmExecutingEvent = async () => {
  ruleMessageBoxExecutingRef.value?.onCheckMessageBoxDialog()
  if (entityStore.entityDetail?.progress.transactionStatus !== 'DOING' || activeTab.value !== 'transfer') {
    try {
      executeLoading.value = true
      await ValuationApi.executeAllDerivativeAdjustmentAutomationRule(entityId.value)
      await entityStore.fetchEntityDetail(entityId.value)
    } catch (error: any) {
      ElMessage.error(error?.message)
      loading.value = false
    } finally {
      executeLoading.value = false
    }
  }
}

/**
 * @description: 修改规则启用状态
 * @param {object} row
 * @param {boolean} status
 */
const onChangeStatus = async (row: any, status: boolean) => {
  try {
    if (
      ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
      !currentEntityPermission.value?.automationRule?.update
    ) {
      ElMessage.warning(t('message.noPermission'))
      return
    }
    loading.value = true
    const params = {
      enabled: status
    }
    await ValuationApi.switchDerivativeAdjustmentAutomationRuleStatus(
      entityId.value,
      row.derivativeAdjustmentAutomationRuleId,
      params
    )
    ElMessage.success(status ? t('message.ruleEnabled') : t('message.ruleDisabled'))
    getAutomationRuleList()
  } catch (error: any) {
    console.log(error)
    transactionStore.automationRuleList.list[row.rowIndex].enabled = status
    transactionStore.automationRuleList.list[row.rowIndex].enabled = !status
    ElMessage.error(error.message)
    loading.value = false
  }
}

/**
 * @description: 添加规则
 */
const onAddRule = () => {
  if (
    ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
    !currentEntityPermission.value?.automationRule?.create
  ) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  if (entityStore.entityDetail?.progress.transactionStatus === 'DOING') {
    ruleMessageBoxRef.value?.onCheckMessageBoxDialog()
    return
  }
  ruleDialogMode.value = 'add'
  currentRuleData.value = {}
  ruleDialogRef.value.onCheckRuleDialog()
}

/**
 * @description: 编辑规则
 * @param {object} row
 */
const onEditRule = (row: any) => {
  if (
    ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
    !currentEntityPermission.value?.automationRule?.update
  ) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  if (entityStore.entityDetail?.progress.transactionStatus === 'DOING') {
    messageBoxType.value = 'message'
    ruleMessageBoxRef.value?.onCheckMessageBoxDialog()
    return
  }
  ruleDialogMode.value = 'edit'
  currentRuleData.value = row
  ruleDialogRef.value.onCheckRuleDialog()
}

/**
 * @description: 删除规则确认
 */
const onConfirmDeleteEvent = () => {
  if (messageBoxType.value === 'delete') {
    onDeleteRule(currentRuleData.value)
  } else {
    ruleMessageBoxRef.value?.onCheckMessageBoxDialog()
  }
}
const onCancelEvent = () => {
  ruleMessageBoxRef.value?.onCheckMessageBoxDialog()
}

/**
 * @description: 获取辅助核算列表
 */
const getAuxiliaryTypeList = async () => {
  try {
    const { data } = await LedgerApi.getAuxiliaryTypeList(entityId.value, { page: 1, limit: 100 })
    auxiliaryTypeList.value = data.list
  } catch (error) {
    console.error(error)
  }
}

/**
 * @description: 获取衍生品列表
 */
const getDerivateList = async () => {
  try {
    const res = await ValuationApi.getDerivativeList(entityId.value, { page: 1, limit: 500 })
    derivateOptions.value = res?.data.map((item) => {
      return {
        id: item.asset.assetId || '',
        value: item.asset.assetId || '',
        label: item.name || '',
        icon: item.platform?.logo || '',
        alt: item.platform?.logo || ''
      }
    })
  } catch (error: any) {
    console.error(error)
  }
}

watch(
  () => route.name,
  async () => {
    if (route.name === 'entity-valuation-automation') {
      try {
        loading.value = true
        getAutomationRuleList()
        nextTick(() => {
          initSortable()
        })
        await transactionStore.autoMationRuleDataInit(entityId.value, 'derivativeAdjustment')
        const { data } = await ConfigApi.searchCurrencyList({ recommend: true, type: 'CRYPTO', entityId: 0 })
        currencyList.value = data
        getAuxiliaryTypeList()
        getDerivateList()
      } catch (error) {
        console.log(error)
      }
    }
  },
  { immediate: true }
)

onBeforeUnmount(() => {
  valuationStore.derivativeAdjustmentAutomationRuleList = {
    list: [],
    totalCount: 0
  }
})
</script>

<style lang="scss">
.elv-valuation-automation-header-info {
  display: flex;
  padding: 10px 30px 9px 20px;
  width: 100%;
  box-sizing: border-box;
  align-items: baseline;
  justify-content: space-between;
  position: relative;
  height: 40px;

  p {
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
  }

  .elv-valuation-automation-operating {
    position: absolute;
    right: 30px;
    top: 6px;
  }

  .elv-financials-header-main-right__addRule {
    padding: 0 12px;
    position: absolute;
    right: 0px;
    top: -44px;
    margin-left: 0;

    .icon {
      fill: #fff;
      margin-right: 4px;
    }
  }

  .elv-valuation-automation-rule-button {
    display: flex;
    align-items: center;
    cursor: default;

    .elv-valuation-automation-rule-button__execute {
      height: 27px;
      display: flex;
      align-items: center;
      cursor: pointer;
      font-family: 'Plus Jakarta Sans';
      font-weight: 500;
      font-size: 12px;
      line-height: 13px;
      color: #636b75;
      border: 0px;
      padding: 0px;

      .el-icon.is-loading {
        margin-right: 0px;

        svg {
          width: 16px;
          height: 16px;
          margin-right: 0px;
        }
      }

      &.is-disabled {
        cursor: not-allowed;
      }

      &:not(.is-disabled):hover,
      &:not(.is-disabled):focus {
        background-color: #fff;
        color: #1753eb;

        .elv-valuation-automation-rule-button__execute-icon {
          fill: #1753eb;
        }
      }

      svg {
        margin-right: 6px;
      }

      span {
        display: flex;
        align-items: center;

        img {
          width: 12px;
          height: 12px;
          display: block;
          margin-right: 6px;
          animation: loading-rotate 2s linear infinite;
        }
      }
    }
  }
}

.elv-valuation-automation-container {
  width: 100%;
  height: calc(100% - 40px);
  position: relative;
}

.elv-automation-confirm-dialog.elv-confirm-info-dialog.is-longer {
  width: 700px;

  .el-dialog__header {
    font-weight: 700;
  }

  .el-dialog__body {
    padding: 24px 24px 30px;
  }
}

.elv-automation-confirm-info__list {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 22px;

  .elv-automation-confirm-info__item {
    display: flex;
    flex-direction: column;

    h4 {
      color: #636b75;
      font-family: 'Plus Jakarta Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 18px;
    }

    li {
      color: #636b75;
      font-family: 'Plus Jakarta Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      list-style: disc;
      margin-left: 18px;
    }

    div {
      width: 100%;
      box-sizing: border-box;
      padding-left: 20px;
      color: #636b75;
      font-family: 'Plus Jakarta Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
    }
  }
}
</style>
<style lang="scss" scoped>
.elv-valuation-automation-page {
  flex: 1;
  min-height: 0;
  position: relative;
}
</style>
